.main-navigation {
	background: $white;
}

.menu {
	margin: 0;
	padding: 0;

	.menu-item {
		position: relative;

		&-has-children {

			a[href="#"] {
				cursor: default;
			}

			&:hover {

				> .sub-menu {
					display: block;
				}
			}
		}

		&-current-parent {

			.sub-menu {
				display: block;

				@media ( min-width: $screen-sm-min ) {
					display: none;
				}
			}
		}

		&:last-of-type {

			.sub-menu {
				right: 0;
			}
		}

		a {
			text-decoration: none;
		}
	}

	.sub-menu {
		display: none;
		margin-left: $grid-gutter-width / 2;
		z-index: 1;

		@media ( min-width: $screen-sm-min ) {
			position: absolute;
			top: 100%;
			margin-left: 0;
			padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
			background: $white;
			white-space: nowrap;
		}
	}
}
