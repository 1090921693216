/**
 * Fonts.
 */
$font-family-base: 'lato';
$font-size-base: 14px;
$font-size-h1: 28px;
$font-size-h2: 22px;
$font-size-h3: 18px;

/**
 * Colors.
 */
$dunkelbraun: #361f04;
$braun: #562f00;
$dunkelbeige: #bc996e;
$beige: #e5d6c5;
$white: #ffffff;
$hellbeige: #d3b99a;
$yellow: #f1d600;
