@font-face {
	font-family: 'lato';
	src: url( '../fonts/lato-regular-webfont.woff2' ) format( 'woff2' ),
	     url( '../fonts/lato-regular-webfont.woff' ) format( 'woff' );
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lato';
	src: url( '../fonts/lato-bold-webfont.woff2' ) format( 'woff2' ),
	     url( '../fonts/lato-bold-webfont.woff' ) format( 'woff' );
	font-weight: bold;
	font-style: normal;
}
