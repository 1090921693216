* {
	min-width: 0;
	min-height: 0;
}

html {
	overflow-x: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: scrollbar;
}

body {
	overflow-x: hidden;
}

img,
iframe,
svg {
	max-width: 100%;
}

img {
	height: auto;
}

ul,
ol {
	padding: 0;
	list-style-type: none;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	font-size: 16px;
}

label {
	display: block;

	input,
	textarea,
	select {
		width: 100%;
		border-radius: 0;
		border: 1px solid #a9a9a9;
		padding: $grid-gutter-width / 6 $grid-gutter-width / 3;
		font-weight: normal;
	}

	input[type="radio"],
	input[type="checkbox"] {
		width: auto;
	}

	textarea {
		min-height: 100px;
		-webkit-appearance: textarea;
		-moz-appearance: textarea;
		appearance: textarea;
	}
}

fieldset {
	min-width: 0;
}

button {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	border: none;
	background: none;

	&:focus {
		outline: none;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {

	a {
		color: inherit;
	}
}

a {
	&:focus {
		text-decoration: none;
	}

	&[href^="tel:"] {

		@media ( min-width: $screen-sm-min ) {
			cursor: text;
			color: inherit;
			text-decoration: none !important;

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: inherit !important;
				background: inherit;
			}
		}
	}

	&[href^="mailto:"] {
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
}
