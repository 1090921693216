/* Hero Image */

.hero-image {

	iframe {
		min-height: 509px;
	}

	img {
		width: 100vw;
	}

	&--text {
		padding: 1em 3em;
		margin-top: -150px;
		color: $white !important;
		text-align: center;
		margin-bottom: 3em;

		@media ( max-width: $screen-xs-max ) {
			margin: 0;
			padding: 1em 30px;
		}

		.impressum &,
		.datenschutz & {
			text-align: left;
		}
	}
}

.ohne-btn {
		padding-bottom: 2em;
	}

/* Video */
.video {
	text-align: center;
	padding: 2em;

	video {
		display: block;
		margin: auto;
		min-width: 50%;

		@media ( max-width: $screen-xs-max ) {
			max-width: 100%;
		}
	}
}

/* Buttons */
.btn {
	color: $beige !important;
	background-color: $dunkelbraun;
	margin: 2em auto;
	padding: 0.5em;
	max-width: 15em;
	display: block;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;

	&:hover {
		background-color: $dunkelbeige;
		color:  $dunkelbraun;
		display: block;
		transition: ease 0.5s;
		text-decoration: none !important;
	}

	&--beige {
		background: none;
		text-transform: none;
		margin-top: 0.5em !important;
	}

	@media (min-width: $screen-sm-min) {
		&--mobil {
		margin-bottom: 0;
		margin-top: 0;
		}
	}
}

.flex {
	> a.btn {
		margin: 2em 1em;
		padding: 0.5em 2em; 
	}
}

/* News */

.news {
	background-image: url( '/assets/img/header/hotel-berlin-uebernachten-leipzig-start-3-news-header.jpg' );
	padding: 2em;

	@media ( min-width: $screen-xs-min ) {
		padding: 3em 0em 1em;
	}
}

.news-block {
	padding: 1em;
	width: 1200px;
	margin: auto;
	text-align: center;

	.date {
		margin-bottom: -30px;
	}

	.border-left {
		border-left: 1px solid $beige;

		@media ( max-width: $screen-xs-max ) {
			border-left: none;
			border-top: 1px solid $beige;
			padding-top: 2em !important;
		}
	}

	article {
		padding: 1em;
		overflow: hidden;
		height: 100%;

		@media ( min-width: $screen-xs-min ) {
			padding: 1em 2em 1em 2em;
		}

		a {
			float: right;
		}
	}
}

/* Arrangements */

.arrangement {
	padding: 1em 2em 1em 2em;
	text-align: center;
	height: 100%;

	@media ( max-width: $screen-sm-max ) {
		margin-bottom: 3em;
		height: auto;
	}

	svg {
		margin: auto;
		background-color: $beige;
		fill: $braun;
		margin-top: -40px;
		width: 50px;
		height: 50px;
		padding: 0.5em;
	 }

	p {
		text-align: left;
	}

	.btn {
		margin-bottom: 0;
	}
}

.arrangement-background {
	background-image: url( '/assets/img/header/hotel-berlin-uebernachten-leipzig-start-2-arrangement-header.jpg' );
	padding: 4em 0em 1em;

	.col-sm-3 {

		@media ( max-width: $screen-sm-min ) {
			min-width: 30%;
		}

		@media ( max-width: $screen-sm-max ) {
			min-width: 33%;
		}
	}

	@media ( max-width: 500px ) {
		padding: 4em 1em;
	}
}

.arrangement-background>div {
	max-width: 1200px;
	margin: auto;
}

/* Galerie */

.gallery {
	clear: both;
	min-height: auto !important;

	ul {
		margin: 0;

		li {
			display: flex;
			width: 50%;
			margin: 0;
			float: left;
			border-top: 6px solid;
			border-color: inherit;

			&:nth-child(2n) {
				border-left: 3px solid;
			}
			&:nth-child(2n-1) {
				border-right: 3px solid;
			}

			&:first-child,
			&:nth-child(2) {
				border-top: none !important;
			}

			.browserhacks & {
				display: table;
			}

			&::before {
				content: '';
				display: block;
				margin: 0;
				padding-bottom: 59%;

				.browserhacks & {
					content: none;
				}

				@media (max-width: 1500px) {
					padding-bottom: 80%;
				}

				@media (max-width: 1150px) {
					padding-bottom: 120%;
				}

				@media (max-width: $screen-xs-max ) {
					padding-bottom: 55%;
				}

				a {

					.browserhacks & {

						padding-bottom: 59%;

						@media (max-width: 1150px) {
							padding-bottom: 120%;
						}

						@media (max-width: $screen-xs-max ) {
							padding-bottom: 55%;
						}
					}
				}
			}

			a {
				display: block;
				width: 100%;
				background-position: center;
				background-size: cover;

				.browserhacks & {
					padding-bottom: 100%;
				}
			}
		}
	}
}

.beige {

	.gallery {

		ul {

			li {
				border-color: $beige;
			}
		}
	}
}

.dunkelbeige {

	.gallery {

		ul {

			li {
				border-color: $hellbeige;
			}
		}
	}
}


.vier {
	overflow: hidden;

	ul {
		margin: -6px -3px;

		li {
				width: 25%;

				&:nth-child(1n) {
					border-top: 6px solid $beige !important;
					border-right: 3px solid $beige !important;
					border-bottom: 0px solid $beige !important;
					border-left: 3px solid $beige !important;
				}
		}
	}
}

.eins {

	ul {

		li {
				width: 100%;
		}
	}
}

.angebote {
	min-height: 550px;
	@media ( max-width: $screen-sm-min ) {
		min-height: 350px;
	}
}

.big-img {
	height: 100%;
	position: relative;

	@media ( max-width: $screen-sm-min ) {
		height: 350px;
	}

	@media ( max-width: $screen-xs-min ) {
		height: 180px;
	}

	span {
		background-size: cover;
		background-position: center;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
	}
}

/* Karte */
iframe {
	width: 100%;

	@media ( min-width: $screen-sm-min ) {
		height: 100%;
	}
}

.block {
	display: block;
}

/* Allgemeines */
.bg-img {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.braun {
	color: $white;
	background-color: $braun;

	&--transparent {
		background-color: rgba( $braun, .88 );
	}
}

.beige {
	background-color: $beige;
	color: $dunkelbraun;
}

.dunkelbeige {
	background-color: $hellbeige;
	color: $dunkelbraun;

}

.center-block {
	float: none;
}

.text-middle {
	padding: 2em;

	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4 {
		@media ( max-width: $screen-sm-min ) {
			text-align: center;
		}
	}

	@media ( min-width: $screen-sm-min ) {
		max-width: 450px;
	}
}

.hidden {
	display: none;
}

.right {
	float: right;
}

.col-sm-6 {

	@media ( max-width: $screen-xs-max ) {
		min-width: 100%;
	}
}

.zimmer-preise {

	.row {

		.col-sm-3 {

			padding: 0;
		}
	}
}

.team-mitglied {
	$team-gutter: 6px;
	margin-right: -$team-gutter / 2;
	margin-left: -$team-gutter / 2;

	> div {
		margin-bottom: $team-gutter;
		padding-left: $team-gutter / 2;
		padding-right: $team-gutter / 2;

		@media ( max-width: $screen-xs-min - 1 ) {
			width: 100%;
		}
	}

	.text-middle {
		max-width: none;

		@media ( max-width: $screen-xs-max ) {
			padding-left: 0;
			padding-right: 0;
		}

		h3 {
			font-size: 16px;
		}
	}

	.angebote {
		min-height: 250px;

		@media ( max-width: $screen-xs-max) {
			height: 200px !important;
		}
	}
}

.zimmer-preise {

	.gallery {
		@media ( max-width: $screen-xs-max) {

			li {
					width: 100%;
					border: none !important;

					&:nth-child(-n+4){
						display: none;
				}
			}
		}
	}
}

.mbl {
	.gallery {
		@media ( max-width: $screen-xs-min) {

			li {
					width: 100% !important;
					border: none !important;

					&:nth-child(-n+4){
						display: none;
				}
			}
		}
	}
}

.preistabelle {
	margin-bottom: 2em;
	line-height: 2.2em;

	tr{

		border-bottom: 1px solid $dunkelbraun;

		&:last-child {
			border: none;
		}

		td {
			vertical-align: top;

			&:last-child {
				padding-left: 3em;
				text-align: right;

				@media ( max-width: 420px ) {
				padding-left: 1em;
				}
			}
		}
	}
}

.preise-arrangement {

	tr {

		td {
			line-height: 1.6em;
			padding: 0.5em 0em;

			&:last-child {
				text-align: right;
			}
		}
	}
}

.termine.preistabelle {

	margin: auto;
	width: 70%;

	@media (max-width: $screen-xs-max) {
		width: 90%;
	}

	.border-bottom-beige {
		border-bottom: 1px solid $beige !important;
			}

	tr {
		border-bottom: 1px solid $beige;


		td {

			&:first-child {
				text-align: left;
			}
		}
	}
}


ul {
	list-style-position: outside;
	padding-left: 1em;
	text-align: left;

	li {
		list-style-type: disc;
	}
}

/* Aktuelles */
.beitrag {
	padding-bottom: 1em;
	margin-bottom: 2em;

	&--last {
		padding-bottom: 0;
		margin-bottom: 0;
	}


	h2 {
		padding-bottom: 0;
		margin-top: -10px;
	}

	.date {
		padding-bottom: 0;
	}
}

.border-bottom {
	border-bottom: 1px solid $beige;
}


.uebersicht-arrangements {
	padding-top: 1em !important;

	@media (max-width: $screen-xs-max ) {
			padding-top: 6em !important;
		}

	.row {
		padding: 2em 0em;

		@media (max-width: $screen-xs-max ) {
			padding: 0em;
		}
	}
}

label {
	padding: 0.5em 0em;

	input,
	textarea {
		border: none !important;
	}
}

.contact,
.connections {

	.col-sm-6 {
		@media (max-width: 1000px) {
			width: 80%;
		}
	}
	@media ( max-width: $screen-sm-min ) {
			padding: 0px 15px;
		}

	.col-sm-6 {
		float: none;
		margin: auto;
		padding: 2em 1em 1em;
	}

	.table-container {
		overflow-x: auto;
	}

	table {
		width: 100%;
		margin-bottom: 1em;

		th {
			text-align: left;
		}

		td,
		th {
			padding: .3em .5em;
		}

		tr:nth-of-type(odd) {
			background: lighten($dunkelbeige,1%);
		}
	}
}

.site-content {
	a {
	color: currentColor;
	font-family: lato;
	font-weight: bold;

	&:hover {
		text-decoration: underline;
		color: $dunkelbraun;
		}
	}
}

span>p {
	font-weight: 500;
	padding-bottom: 0em !important;
	padding-top: 1em;
}

.anfahrt {

	.h1 {
		padding-bottom: 0 !important;
	}

	h3 {
		padding-top: 2em;
		padding-bottom: 1em;
	}

	.no-top > p {
		padding-top: 0em !important;
	}
}

.kalender {
	padding-bottom: 2em;

	h4 {
		padding-top: 2em;
	}
}

.teaser {

	.btn {
		margin: 0 auto 1.5em !important;
	}
}

.buchen-frame {
	height: 515px;
	border: none;
	padding: 2em;
}

.hotel>.col-sm-3 {
	margin-bottom: 3em;

	@media (max-width: $screen-xs-max ) {
		margin-bottom: 0;
		width: 100%;
	}
}

.wp [class*="col"] {

	@media ( min-width: $screen-sm-min ) {
		padding-left: 0;
		padding-right: 0;
	}

	ul {
		padding: 0;
	}
}

.uebersicht-urlaub-leipzig-arrangements {
	padding: 4em 0em;

	.row:first-child {
		padding-bottom: 3em;

		@media (max-width: $screen-sm-min) {
			padding-bottom: 0;
		}
	}
}

@media ( max-width: $screen-xs-max) {
	.gallery-mobil {
		width: 100%;

		.vier {
			li {
				width: 100%;
				border: none !important;

				&:first-child,
				&:nth-child(2) {
					display: none;
				}
			}
		}
	}
}

@media ( max-width: $screen-xs-min) {
		.vier {

			li {
				width: 100%;
		}
	}
}

@media (max-width: $screen-md-max) {

.hero-image-mobil {
	width: 65%;
	}
}

.response {
	padding-top: 1em;
	text-align: center;
	color: white;
}

.send-success {
	background-color: #21813d;
}

.send-error {
	background-color: #d82c2c;
}

.teaser-zimmer {
	min-height: 280px;
}

.center {
	text-align: center !important;
}

.teaser--new-beds {
	margin: 20px auto;
	font-size: $font-size-base * 1.3;
	background: $yellow;
}


.fp-impressum {
	display: block;
	text-align: right;

	@media ( max-width: $screen-xs-max ) {
		text-align: left;
		padding: 1.5em 0;
	}

	svg {
		height: 225px;
		width: 225px;
	}
}