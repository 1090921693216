.flex {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.column {
	-webkit-flex-direction: column;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.no-wrap {
	-webkit-flex-wrap: nowrap !important;
	    -ms-flex-wrap: nowrap !important;
	        flex-wrap: nowrap !important;
}
.justify-start {
	-webkit-justify-content: flex-start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}
.justify-between {
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}
.justify-around {
	-webkit-justify-content: space-around;
	    -ms-flex-pack: distribute;
	        justify-content: space-around;
}
.justify-end {
	-webkit-justify-content: flex-end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}
.justify-center {
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}
.align-center {
	-webkit-align-items: center;
	    -ms-flex-align: center;
	            -ms-grid-row-align: center;
	        align-items: center;
}
.align-end {
	-webkit-align-items: flex-end;
	    -ms-flex-align: end;
	            -ms-grid-row-align: flex-end;
	        align-items: flex-end;
}
.order--1 {
	-webkit-order: -1;
	    -ms-flex-order: -1;
	        order: -1;
}
.flex-sm {
	@media (min-width: $screen-sm-min) {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
}
.flex-md {
	@media (min-width: $screen-md-min) {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}