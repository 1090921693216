h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	text-transform: uppercase;
	padding-bottom: 1em;
	line-height: 1.4em;
}

h2,
.h2,
h3,
.h3,
h4,
.h4 {
	padding-bottom: 0;
}

p,
.p {
	font-family: $font-family-base;
	font-size: $font-size-base;
	padding-bottom: 1em;
	line-height: 1.6em;
}

.big-price {
	font-size: 18px;
	text-align: right;

	@media ( max-width: $screen-sm-min ) {
			text-align: center;
		}

	.small {
		font-size: $font-size-base;
	}
}

