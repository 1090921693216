.site-footer {
	li {
	list-style-type: none !important;
	}

	background-color: $dunkelbraun;
	color: $beige;
	min-width: 100vw;
	padding: 1em 8em 0;

	@media ( max-width: $screen-xs-max ) {
		display: block;
	}

	a {
		color: $beige;

		&:hover {
			text-decoration: underline;
			color: $white;
		}
	}

	.copy {
		text-align: left;

		@media ( max-width: $screen-xs-max ) {
			text-align: center;
		}

		p {
			padding-bottom: 0;
		}
	}

	.feuerpanda {

		li {
			text-align: right;

			@media ( max-width: $screen-xs-max ) {
				text-align: center;
				padding-top: 1.5em;
			}
		}

		

		.icon {

			&:hover {
	
				svg {
					transform: scale(1.1);
				}
			}
	
			svg {
				width: 30px;
				height: 30px;
			}
		}
	}

	

	.row {

		@media ( max-width: $screen-xs-max ) {
			display: block;
		}
	}
}
