.site-header {
	padding: 0em 8em;

	@media ( max-width: 1310px ) {
		padding: 0em 5em;
	}

	@media ( max-width: 900px ) {
		padding: 0em 2em;
	}

	li {
		list-style-type: none;
	}

	.site-branding {
		padding-top: 0.5em;

		@media ( max-width: 1280px ) {
			max-width: 275px;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 0.5em;
		}

		@media ( max-width: 988px ) {
			margin-top: 2em;
		}

		@media ( max-width: $screen-sm-min ) {
		max-width: 200px;
		margin-top: 0;
		}
	}

	a {
		text-transform: uppercase;
		display: block;

		@media ( max-width: 1100px ) {
			font-size: 12px;
			text-transform: none;
		}
	}
}
