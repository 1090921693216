:root {
    --cc-btn-primary-bg: var( --cc-btn-secondary-bg );
    --cc-btn-primary-text: var(--cc-text);
    --cc-btn-primary-hover-bg: var( --cc-btn-secondary-hover-bg );
    --cc-toggle-bg-on: var(--cc-text);
}


.cc_div .cc-link{
    color: var(--cc-text);
    border-bottom: 1px solid var(--cc-text);
}

#c-bns button:first-child,
#s-bns button:first-child{
    color: var(--cc-text);
    background: var(--cc-btn-primary-bg);
}