.off-canvas {
	background-color: $white;

	&__toggle {
		margin-top: 1em;
	}
}

.content-overflow {

	@media ( min-width: $screen-sm-min ) {
		display: flex;
		flex-direction: column-reverse;
		padding: 0;
	}
}

.topnavi {
	margin-bottom: 0.5em;
	margin-right: 0.5em;

	@media ( max-width: $screen-xs-max ) {
		margin-top: 2em;
	}

	a {
		color: $dunkelbeige;
		margin: 1em 1em 0em;

		@media ( max-width: 1210px ) {
			margin: 1em 0.5em 0em;
		}

		@media ( max-width: 900px ) {
			font-size: 9.5px;
		}

		@media ( max-width: $screen-xs-max ) {
			font-size: 14px;
		}

		&:hover {
		color: $braun;
		}
	}

	.menu-item-current > a,
	.menu-item-current-parent > a {
		color: $dunkelbraun;
	}
}

.main-navigation {
	margin-top: -0.5em;

	.menu-item-current > a,
	.menu-item-current-parent > a,
	a:hover {
		background-color: $beige;
		padding: 0.5em;
		transition: all ease 0.25s;
	}

	a {
		color: $dunkelbraun;
		font-size: $font-size-h3;
		padding: 0.5em;

		@media ( max-width: 1310px ) {
			font-size: 16px;
		}

		@media ( max-width: 1210px ) {
			font-size: 14px;
		}

		@media ( max-width: 1100px ) {
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.justify-between {

		@media ( max-width: 1100px ) {
			justify-content: flex-end;
		}
	}
}

.sub-menu {
	padding: 0 !important;
}

.buchen {
	background-color: $dunkelbraun;
	padding-bottom: 0.5em;

	@media ( max-width: $screen-xs-max ) {
		display: inline-block
	}

	&:hover {
		background-color: $beige;
		transition: all ease 0.25s;
	}

	a {
		color: $white;

		@media ( max-width: $screen-xs-max ) {
			margin-top: 0;
			padding-top: 0.5em;
		}

		&:hover {
			color: $dunkelbraun;
			transition: all ease 0.25s;
		}
	}
}

.buchen.menu-item-current,
.buchen.menu-item-current-parent {
	background-color: $beige;

	a {
		color: $dunkelbraun;
	}
}

.de {
	margin-right: 0 !important;
	padding-right: 0.4em;
	border-right: 2px solid $beige;

	@media ( max-width: $screen-xs-max ) {
		border-right: none;
	}
}

.en {
	margin-left: 0 !important;
	padding-left: 0.4em;

	@media ( max-width: $screen-xs-max ) {
		margin-bottom: 1em !important;
	}

}

.col-sm-4 {
	@media ( max-width: 866px ) and ( max-width: 900px ) {
		max-width: 40%;
	}

	@media ( max-width: $screen-xs-max ) {
		max-width: 100%;
	}
}

.col-sm-8 {
	@media ( min-width: 866px ) and ( max-width: 900px ) {
		width: 60%;
	}
}

.telefon-top {
	width: 15px;
	margin-right: 0.5em;
	height: 15px;
	fill: currentColor;
	vertical-align: middle;
}
.whatsapp-top {
	width: 15px;
	margin-right: 0.5em;
	height: 15px;
	fill: currentColor;
	vertical-align: middle;
}

.footer-menu {
	ul {
		@media ( max-width: $screen-xs-max ) {
		text-align: center;
		}
	}
}

.jf {
	ul {
		text-align: right;
		padding-left: 0;
		@media ( max-width: $screen-xs-max ) {
		text-align: center;
		}
	}
}
