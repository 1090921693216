.site {
	min-height: 100vh;

	@media ( max-width: $screen-xs-max ) {
		width: 100%;
	}
}

.site-content {
	-webkit-flex: 1 0 auto;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
}

.entry-header {
	overflow: hidden;
}

.byline,
.updated:not( .published ){
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}

.page-content,
.single .entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.static {
	position: static;
}
